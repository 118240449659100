import React from 'react'
import styled from 'styled-components';
import { Img } from './misc';
import { css } from '../utils/style';

export default ({ data }: { data: any }) => (
  <Hero>
    <HeroImage alt={data.name} sizes={data.heroImage.sizes} />
    <HeroDetails>
      <HeroHeadline>{data.name}</HeroHeadline>
      <HeroTitle>{data.title}</HeroTitle>
      <p>{data.shortBio.shortBio}</p>
    </HeroDetails>
  </Hero>
)


export const Hero = styled.div`
position: relative;
background: #000;
color: #fff;
text-align: center;
`

export const HeroImage = styled(Img)`
/*
Ensure golden ratio for the hero size while limiting it to some extend to
the viewport width
*/
height: 61.8vh;
max-height: 600px;
`

const HeroDetails = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  left: 50 %;
  bottom: 0;
  transform: translate(-50 %, 0);
  font - size: 14px;
  padding: 0 0.5em;
`

const HeroHeadline = styled.h3`
margin: 0;

@media(min - width: 600px) {
  .heroDetails {
     font - size: 16px;
   }
 }
 
 @media(min - width: 1000px) {
  .heroDetails {
     font - size: 20px;
   }
 }

`

const HeroTitle = styled.p`
margin: 0;
font - size: 1.125em;
font - weight: bold;
`

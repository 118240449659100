// TODO: As alternative play with Ghost:
// Gatsby-contentful
// + own the data, own the code
// - own the SEO

// Ghost
// + super editor
// + super SEO?
// - don't own the code/data?

// Jekyl
// - ruby, too much markdown hassle

// what else ?

import React from 'react'
import { Link } from '@reach/router'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import Layout, { SEO } from '../components/layout';
import { Hero, HeroImage } from '../components/hero';
import { Box, Heading, Header } from '../components/misc';
import Disqus from '../components/Disqus';
import { PublishDate } from '../components/article-preview';
import styled, { css } from '../utils/style';

const BlogPostTemplate: React.FunctionComponent = (props) => {
  const post = get(props, 'data.contentfulBlogPost')
  const canonicalUrl = `https://patrickroza.com${blogPostPath(post)}`
  const publishDateRaw = new Date(post.publishDateRaw)
  const currentlyPublished = new Date() > publishDateRaw

  const today = new Date()
  const postSeriesItems = !post.series ? [] : post.series.blog_post.filter((x: any) => process.env.GATSBY_ENV !== "production" || new Date(x.publishDateRaw) < today).sort((a: any, b: any) => new Date(a.publishDateRaw) - new Date(b.publishDateRaw))

  return (
    <Layout>
      <SEO canonicalUrl={canonicalUrl} title={post.title} article={true} image={`https:${post.heroImage.file.url}`} description={post.description.childMarkdownRemark.excerpt} />
      <Box maxWidth={1040} margin="0 auto;">
        <article>
          <Header px={["5vw", 0]}>
            <Heading.h1 fontSize={[5, 5, 6]} my={[2, 2, 3]}>{post.title}</Heading.h1>
            <InfoBox>
              <p
                style={{
                  display: 'block',
                }}
              >
                <PublishDate currentlyPublished={currentlyPublished}>{post.publishDate}</PublishDate>
              </p>
              {post.series && <p>Part of the <a href="#series"><i>{post.series.title}</i></a> series</p>}
            </InfoBox>
          </Header>
          <figure>
            <PostHero>
              <HeroImage alt={post.title} sizes={post.heroImage.sizes} />
            </PostHero>
          </figure>
          <PostBox bg="white" margin="0 auto;" padding={["5vw 7vw 0", "5vw 7vw 0", "70px 100px 0"]}>
            <PostWrapper dangerouslySetInnerHTML={{
              __html: post.body.childMarkdownRemark.html,
            }}
            />
            <div>
              {post.series && <Box id="series"><p>Part of the <i>{post.series.title}</i> series</p>
                {postSeriesItems.length > 1 && <ul>
                  {postSeriesItems.map((p: any) => <li key={`post-${p.slug}`}>
                    <Link to={blogPostPath(p)} style={{ fontWeight: p.slug === post.slug ? "bold" : "normal" }} title={`Published: ${p.publishDate}`}>{p.title}</Link>
                  </li>)}
                </ul>
                }</Box>}

              <ConnectSection>
                {post.links && post.links.length && <Box mb={[1, 2]}>
                  <i>Prefer to read or respond elsewhere?</i>
                  <div>{post.links.map((link: any) => <span><a href={link.url} target="_blank">{link.title || link.url}</a>&nbsp;</span>)}</div>
                </Box>
                }
                <Disqus url={canonicalUrl} identifier={`post-${post.id}`} />
              </ConnectSection>
            </div>
          </PostBox>
        </article>
      </Box>
    </Layout>
  )
}

const ConnectSection = styled.div`
  ${css({ marginTop: [4, 4, 5] })}
`

const InfoBox = styled(Box)`
${css({ display: ['block', 'block', 'flex'] })}
justify-content: space-between;
`

export const blogPostPath = (post: { slug: string }) => `/blog/${post.slug}/`

export default React.memo(BlogPostTemplate)

const PostHero = styled(Hero)`
${css({ margin: ["0 0 -100px", "0 -4vw -100px", "0 -4vw -180px"] })}
`

const PostBox = styled(Box)`
position: relative;
box-sizing: content-box;

&:before, &:after {
  content: "";
  position: absolute;
  top: 15px;
  z-index: -1;
  display: block;
  width: 20px;
  height: 200px;
  background: rgba(39,44,49,.15);
  -webkit-filter: blur(5px);
  filter: blur(5px);
  ${css({ display: ["none", "block"] })};
}
&:before {
  left: -5px;
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
&:after {
  right: -5px;
  -webkit-transform: rotate(5deg);
  transform: rotate(5deg);
}
`

const PostWrapper = styled.div`
${css({ fontSize: [2, 3, 3] })}

.gatsby-highlight, .gist-file {
  font-size: 12px;
  ${css({ marginTop: [1, 2, 2] })}
  ${css({ marginBottom: [3, 4, 4] })}
}

blockquote {
  margin: 0 0 1.5em;
    padding: 0 1.5em;
    border-left: 3px solid #3eb0ef;
}

>p:first-child {
  ${css({ fontSize: [4, 5, 5] })}
  line-height: 1.25em;
  font-family: "Georgia, serif";
  ${css({ marginBottom: [2, 3, 3] })}
}

twitter-widget {
  margin-left: auto;  
  margin-right: auto;
  ${css({ padding: [2, 2, 3] })}
}`

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
      contentfulBlogPost(slug: {eq: $slug }) {
          slug
          id
          title
          description {
            childMarkdownRemark {
                excerpt
            }
          }
        publishDate(formatString: "MMMM Do, YYYY")
        publishDateRaw: publishDate
        heroImage {
          file {
            url
          }
          sizes(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulSizes_withWebp
        }
      }
      links {
        title
        url
      }
      series {
          title
          blog_post {
            title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            publishDateRaw: publishDate
          }
      }
      body {
          childMarkdownRemark {
        html
      }
    }
    }
  }
`

// const Hero = styled.div`
// display: flex;
// justify-content: center;
// align-items: center;

// height: 12.5em;
// background: #e1e1e1;
// margin: -1em -2.5em 1em;
// font-size: 2em
// `

import React from "react"
import { DiscussionEmbed } from "disqus-react"

const Disqus = ({ className = undefined, url, identifier }) => {

  const disqusConfig = {
    url,
    identifier,
    // title
  }
  return <>
    <DiscussionEmbed className={className} shortname={"patrickroza"} config={disqusConfig} />
  </>
}

export default Disqus

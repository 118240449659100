import React from 'react'
import Link from 'gatsby-link'

import styled from 'styled-components';
import { Img } from './misc';
import { blogPostPath } from '../templates/blog-post';

export default ({ article }: { article: any }) => {
  const publishDateRaw = new Date(article.publishDateRaw)
  const currentlyPublished = new Date() > publishDateRaw
  return (
    <div>
      <Link to={blogPostPath(article)}><Img alt="" sizes={article.heroImage.sizes} /></Link>
      <PreviewTitle>
        <Link to={blogPostPath(article)}>{article.title}</Link>
      </PreviewTitle>
      <PublishDate currentlyPublished={currentlyPublished}>{article.publishDate}</PublishDate>
      <p
        dangerouslySetInnerHTML={{
          __html: article.description.childMarkdownRemark.html,
        }}
      />
      <i>{article.body.childMarkdownRemark.fields.readingTime.text}</i>
    </div>
  )
}

export const PublishDate = styled.small<{ currentlyPublished: boolean }>`
  color: ${props => props.currentlyPublished ? "inherit" : "red"}
`

const PreviewTitle = styled.h3`
font-size: 1.5em;
`

const Tag = styled.div`
color: #A0A0A0;
text-decoration: none;
display: inline-block;
padding: .33333rem .5rem;
line-height: 1;
border-radius: 2px;
border: 1px solid #A0A0A0;
margin-right: .5em;`
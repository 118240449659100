import styled from "styled-components";
import { BoxProps, Heading as HeadingOriginal, Box as BoxOriginal, Button, Card, Text as TextOriginal, Flex, Image, Link } from "rebass";
import { MaxWidthProps, MinWidthProps, maxWidth, minWidth, width, SpaceProps, FontSizeProps, FontWeightProps, LineHeightProps, ColorProps, space, fontSize, color } from "styled-system";


/**
 * article grid
 */
export const ArticleList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 5vmin;

  li *:last-child {
    margin-bottom: 8px;
  }
`

export const InlineList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    display: inline-block;
  }
`

const StyledHeading = styled(HeadingOriginal)``

StyledHeading.defaultProps.as = undefined

const Heading = {
  h1: styled(StyledHeading).attrs(() => ({ as: 'h1' }))``,
  h2: styled(StyledHeading).attrs(() => ({ as: 'h2' }))``,
  h3: styled(StyledHeading).attrs(() => ({ as: 'h3' }))``,
  h4: styled(StyledHeading).attrs(() => ({ as: 'h4' }))``,
  h5: styled(StyledHeading).attrs(() => ({ as: 'h5' }))``,
  h6: styled(StyledHeading).attrs(() => ({ as: 'h6' }))``
}

const Text = styled(TextOriginal)``
const Box = styled<React.FunctionComponent<BoxProps & MaxWidthProps & MinWidthProps>>(BoxOriginal as any)(maxWidth, minWidth)
const Header = styled(Box).attrs(() => ({ as: 'header' }))``

const Paragraph = styled(Text).attrs(() => ({ as: 'p' }))``

import GatsbyImage, { GatsbyImageProps } from 'gatsby-image'

const Img = styled<React.FunctionComponent<GatsbyImageProps & SpaceProps & FontSizeProps & FontWeightProps & LineHeightProps & ColorProps>>(GatsbyImage as any)(
  space,
  width,
  fontSize,
  color,
)
export { Box, Heading, Header, Img, Paragraph, Text }


export const Wrapper = styled(Box)`
  width: calc(100% - 10vmin);
  margin: 0 auto;
  padding: 5vmin 0;
`

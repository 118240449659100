import React from 'react'

import { Location } from '@reach/router'

export const withRouter = Component => Props => <Location>{(location) => <Component {...Props} location={location} />}</Location>


// Doesn't work, use `useStaticQuery` hook instead
// export const withStaticQuery = (query) => Component => Props => <StaticQuery
//   query={query}
//   render={data => <Component {...Props} data={data} />} />

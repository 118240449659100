import React from 'react'

import Container from './container'
import Navigation from './navigation'
import { withRouter } from './hocs';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import GlobalStyles from '../utils/style/GlobalStyle';
import styled, { theme, ThemeProvider } from '../utils/style';
import { Box, Wrapper } from './misc';

require("prismjs/themes/prism-okaidia.css")


const Layout: React.FunctionComponent = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <SEO />
      <Container>
        <Navigation />
        {children}
        <Footer>
          <a href="#" onClick={() => window.scroll({ top: 0 })}>Back to top</a>
          <Connect>
            Connect with me:
            <div><a href="https://medium.com/@patroza" target="_blank">
              Medium
          </a></div>
            <div><a href="https://github.com/patroza" target="_blank">GitHub</a></div>
            <div><a href="https://dev.to/patroza" target="_blank">
              <img src="https://d2fltix0v2e0sb.cloudfront.net/dev-badge.svg" alt="Patrick Roza's DEV Profile" height="30" width="30" />
            </a></div>
            <div>
              <a href="https://twitter.com/Patrick_Roza?ref_src=twsrc%5Etfw" target="_blank" className="twitter-follow-button" data-show-count="false">Follow @Patrick_Roza</a>
              <Helmet>
                <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
              </Helmet>
            </div>
          </Connect>
        </Footer>
      </Container>
    </>
  </ThemeProvider>
)

const Footer = styled(Wrapper).attrs(() => ({ as: 'footer' }))`
  display: flex;
  align-items: right;
  iframe {
    margin-bottom: 0;
  }
`

const Connect = styled(Box)`
text-align: right;
margin-left: auto;
`

interface Props { canonicalUrl?: string, title?: string, description?: string, image?: string, keywords?: string[], pathname?: string, article?: true }
const SEO = withRouter(({ canonicalUrl, title, description, location, image, keywords, pathname, article }: Props & { location: { location: Location } }) => {
  const { site: {
    siteMetadata: {
      defaultTitle,
      titleTemplate,
      defaultKeywords,
      defaultDescription,
      siteUrl,
      defaultImage,
      twitterUsername,
    },
  }, } = useStaticQuery(query)
  const seo = {
    title: title || defaultTitle,
    canonicalUrl: canonicalUrl || `${siteUrl}${location.location.pathname === '/' ? '' : location.location.pathname}${location.location.search}`,
    description: description || defaultDescription,
    keywords: keywords ? keywords : defaultKeywords,
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname || '/'}`,
  }
  return (
    <Helmet titleTemplate={titleTemplate} defaultTitle={seo.title}>
      <meta name="description" content={seo.description} />
      {seo.canonicalUrl && <link rel="canonical" href={seo.canonicalUrl} />}
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && (
        <meta property="og:type" content="article" />
      )}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.keywords && <meta name="keywords" content={seo.keywords.join(',')} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
})

export { SEO }


export default withRouter(React.memo(Layout))

const query = graphql`
query LayoutQuery {
      site {
    siteMetadata {
      defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  }
}
}
`
import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'
import Layout from '../components/layout';
import { ArticleList, Heading, Wrapper } from '../components/misc';

const RootIndex = ({ data }: { data: any }) => {
  const posts: any[] = data.allContentfulBlogPost.edges
  const [author] = data.allContentfulPerson.edges

  const today = new Date()

  return (
    <Layout>
      <div>
        <Hero data={author.node} />
        <Wrapper>
          <h2>Recent articles</h2>
          <ArticleList>
            {posts.filter(x => process.env.GATSBY_ENV !== "production" || new Date(x.node.publishDateRaw) < today).map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              )
            })}
          </ArticleList>
        </Wrapper>
      </div>
    </Layout>
  )
}

export default React.memo(RootIndex)


export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          publishDateRaw: publishDate
          tags
          heroImage {
            sizes(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
             ...GatsbyContentfulSizes_withWebp
            }
          }
          body {
            childMarkdownRemark {
              fields {
                readingTime {
                  text
                }
              }
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(filter: { id: { eq: "3c56de93-d279-5889-8563-5298ad412080" } }) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            sizes(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
  }
`
